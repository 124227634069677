import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { graphql } from 'gatsby'
import { Layout, SEO, HeroHeading, Section, Collapse } from '@components'
import { Page } from '@types'
import { Container } from '@bootstrap-styled/v4'
import { useFAQs } from '@hooks'

const FAQPage: React.FC<Page<unknown>> = ({ data, location }) => {
  const { title, content } = data.wpgraphql.page
  const { transitionDurations } = useContext(ThemeContext)
  const faqs = useFAQs()

  return (
    <Layout>
      <SEO title={title} pageUrl={location.pathname} />
      <Section hasBg verticalPadding>
        <Container>
          <div>
            <HeroHeading
              className="mb-5"
              data-sal="slide-up"
              data-sal-duration={transitionDurations.sal}
            >
              {title}
            </HeroHeading>
          </div>
          {content && (
            <div
              className="mb-5"
              dangerouslySetInnerHTML={{ __html: content }}
              data-sal="slide-up"
              data-sal-duration={transitionDurations.sal}
            />
          )}
          {faqs.length > 0 && (
            <div className="mb-4" data-sal="slide-up" data-sal-duration={transitionDurations.sal}>
              {faqs.map(faq => (
                <Collapse key={faq.id} id={`collapse${faq.id}`} title={faq.question}>
                  <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                </Collapse>
              ))}
            </div>
          )}
        </Container>
      </Section>
    </Layout>
  )
}

export default FAQPage

export const pageQuery = graphql`
  query {
    wpgraphql {
      page(id: "17", idType: DATABASE_ID) {
        slug
        title(format: RENDERED)
        uri
        databaseId
        isFrontPage
        content(format: RENDERED)
      }
    }
  }
`
